import React, { createContext, useState, useEffect } from "react";

import { post, auth } from '../_api/api';
import { getHash, getUserAgent, getLanguages, getBrowserLanguage, getBWId, setBWId } from '../_helpers/helperfunctions';

const DataContext = createContext();

function DataProvider(props) {
    const [hash] = useState(getHash()); // Get the hash from the url and set it into state, we will need it later
    const [fetchingConfig, setFetchingConfig] = useState(true); // The flag to check if we are fetching config data
    const [fetchingMenu, setFetchingMenu] = useState(false); // The flag to check if we are fetching menu data
    const [fetchError, setFetchError] = useState(null); // The flag when we have an error in any of the fetch operations
    const [shopConfig, setShopConfig] = useState(null);
    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const Language = getBrowserLanguage();
    const [selectedLanguage, setSelectedLanguage] = useState(Language);

    // This effect runs when the context mounts and we have a hash at hand.
    // This is the effect that is first to run and get the config of the shop
    // and the list of merchants open for delivery or pickup if the config says so 
    useEffect(() => {
        const fetchConfig = async () => {
            setFetchingConfig(true); // Set the flag so that SingIn button is disabled

            auth() // Authorize client
                .then(() => {
                    // We have to decide what to present to customer
                    // so we need Config. Get the config from the given hash
                    post('/Shop/get-config', { Hash: hash, BWId: getBWId(), UserAgent: getUserAgent(), })
                        .then(response => {
                            setBWId(response.data.Data.BWId); // Store the BWId returned from api into local storage
                            setShopConfig(response.data.Data); // Store the config data returned from api into state

                            const ShopConfig = response.data.Data;
                            const Config = ShopConfig.Config; // Temp variable to store the config part of the data returned form api, we will need this below

                            // The Brand allows shop order and needs a merchant list, so get the list of merchants to order
                            if (Config.ShowMerchantList) {
                                // Get the list of the merchants that are open for delivery or pickup
                                post('/Shop/get-merchant-list', { BrandId: ShopConfig.BrandId, DeliveryOpen: Config.Delivery, PickupOpen: Config.Pickup })
                                    .then(response => {
                                        setMerchants(response.data.Data);
                                        setFetchingConfig(false);
                                    })
                                    .catch(() => { setFetchError(200); setFetchingConfig(false); });  // An error occured while fetching merchants. Return 200
                            }
                            else // We do not need merchant list, so we should direct to menu page. First fetch the menu
                            {
                                setFetchingMenu(true); // We are fetching the menu, so set the flag to true
                                post('/Shop/get-menu', { 'BrandId': ShopConfig.BrandId, 'MenuId': ShopConfig.MenuId, 'MerchantId': ShopConfig.MerchantId })
                                    .then(response => {
                                        setMenuData(response.data.Data);
                                        setFetchingMenu(false);
                                        setFetchingConfig(false);
                                    })
                                    .catch(() => { setFetchError(300); setFetchingConfig(false); setFetchingMenu(false); }); // An error occured while fetching menu. Return 300
                            }

                            // The brand does not allow shop order. We will just display the menu, so go fetch it
                            if (Config.OnlyMenu) {
                                setFetchingMenu(true); // We are fetching the menu, so set the flag to true
                                post('/Shop/get-menu', { 'BrandId': ShopConfig.BrandId, 'MenuId': ShopConfig.MenuId, 'MerchantId': ShopConfig.MerchantId })
                                    .then(response => {
                                        setMenuData(response.data.Data);
                                        setFetchingMenu(false);
                                        setFetchingConfig(false);
                                    })
                                    .catch(() => { setFetchError(300); setFetchingConfig(false); setFetchingMenu(false); }); // An error occured while fetching menu. Return 300
                            }
                        })
                        .catch((err) => {
                            if(err.Code === 77) setFetchError(77); // The brand is churned. Return 77.
                            else setFetchError(500); // An error occured while fetching shop Config. Return 500
                            setFetchingConfig(false); });
                })
                .catch(() => { setFetchError(400); setFetchingConfig(false); }) // An error occured while authorizing. Return 400
        };

        // If we have a hash at hand, fetch data, else return error 100
        if (hash && (hash !== 'orders'))
            fetchConfig();
        else
            setFetchError(100);
    }, [hash]);

    const selectMerchant = (merchant) => {
        const fetchMenuData = async () => {
            setSelectedMerchant(merchant);
            setFetchingMenu(true); // We are fetching the menu, so set the flag to true
            post('/Shop/get-menu', { 'BrandId': merchant.BrandId, 'MerchantId': merchant.Id })
                .then(response => {
                    setMenuData(response.data.Data);
                    setFetchingMenu(false);
                })
                .catch(() => { setFetchError(300); setFetchingMenu(false); }); // An error occured while fetching menu. Return 300
        };

        if (merchant)
            fetchMenuData();
        else {
            setSelectedMerchant(null);
            setMenuData(null);
        }
    }

    const MenuSets = menuData && menuData.MenuSets;
    const Languages = MenuSets ? getLanguages(MenuSets, selectedLanguage) : {};
    const SelectedMenuSet = MenuSets ? (MenuSets.filter(menu => menu.Language === selectedLanguage)[0] || MenuSets[0]) : {};

    const Credentials = {
        Subscriber: shopConfig && shopConfig.Config && shopConfig.Config.Subscriber,
        SubscriberId: shopConfig && shopConfig.Config && shopConfig && shopConfig.Subscriber && shopConfig.Subscriber.Id,
        BWId: shopConfig && shopConfig.Config && shopConfig.BWId,
        PassId: shopConfig && shopConfig.Config && shopConfig.PassId,
        BrandId: shopConfig && shopConfig.Config && shopConfig.BrandId,
        MenuId: menuData && menuData.Id,
        MerchantId: shopConfig && shopConfig.Config && shopConfig.MerchantId,
        SelectedCurrencyId: SelectedMenuSet && SelectedMenuSet.CurrencyId,
    };

    return (
        <DataContext.Provider
            value={{
                Config: shopConfig && shopConfig.Config,
                Theme: shopConfig && shopConfig.Theme,
                Credentials,
                GDPRContract: shopConfig && shopConfig.GDPRContract,
                SalesContract: shopConfig && shopConfig.SalesContract,
                SelectedMenuSet,
                Merchants: merchants,
                SelectedMerchant: selectedMerchant,
                Languages,
                setSelectedLanguage,
                selectMerchant,
                fetchingConfig,
                fetchingMenu,
                fetchError
            }}>
            {props.children}
        </DataContext.Provider>
    );
}

export { DataContext, DataProvider };