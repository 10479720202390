import React from 'react'

// Material Ui
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

// Components
import Price from '../../price.component';

const useStyles = makeStyles(theme => ({
    flatitem: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        boxSizing: 'border-box',
        display: 'block',
        cursor: 'pointer',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '4px 10px 4px 10px',
        justifyContent: 'center',
    },
    title: {
        width: '100%',
    },
    subtitle: {
        width: '100%',
    },
    spacer: {
        width: '24px',
        height: '24px',
        minWidth: '24px',
        minHeight: '24px',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

function MenuItemFlat(props) {
    const classes = useStyles();
    const { Item, openProductDetails } = props;

    const openDetails = () => {
        if (!Item.NoDetails) openProductDetails(Item)
    }

    return (
        <div className={classes.flatitem} onClick={(openDetails)}>
            <div className={classes.container}>
                {
                    Item.ThumbnailUrl &&
                    <Avatar className={classes.avatar} alt={Item.Name} src={Item.ThumbnailUrl} />
                }
                <div className={classes.content}>
                    <div className={classes.title}><Typography variant='body1' color='textPrimary'>{Item.Title}</Typography></div>
                    {
                        Item.SubTitle &&
                        <div className={classes.subtitle}><Typography variant='body2' color='textSecondary'>{Item.SubTitle}</Typography></div>
                    }
                </div>
                <Price Item={Item} />
                {
                    (Item.NoDetails)
                        ?
                        <div className={classes.spacer} />
                        :
                        <ArrowRightIcon color='primary' />
                }
            </div>
        </div>
    );
}

export default MenuItemFlat;