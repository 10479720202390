import React from 'react'
import { ItemType } from '../../globals';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(() => ({
    carousel: {
        width: '100%',
        maxWidth: '440px',
        maxHeight: '60px',
        padding: '8px',
        boxSizing: 'border-box',
    },
}));

export default function Carousel(props) {
    const classes = useStyles();
    const { Items, selected, setSelected } = props;

    const handleChange = (event, newValue) => {
        setSelected(newValue);
    };

    return (
        <div className={classes.carousel}>
            <Tabs
                value={selected}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {
                    Items.filter(item => (item.Type === ItemType.SubCategory) || (item.Type === ItemType.MainCategory)).map((i) => <Tab key={i.Id} label={i.Title} />)
                }
            </Tabs>
        </div>
    );
}