import React from 'react'
import { ItemType } from '../../globals';

// Components
import MenuItemRaised from './items/raised.item';
import MenuItemRaisedRTL from './items/raised.rtl.item';
import MenuItemFlat from './items/flat.item';
import MenuItemFlatRTL from './items/flat.rtl.item';
import MenuItemWithCart from './items/withcart.item';
import MenuItemDetailed from './items/detailed.item';
import MainCategory from './items/maincategory.item';
import SubCategory from './items/subcategory.item';

const MenuItem = (props) => {
    const { Item, openProductDetails } = props;

    if (Item.Type === ItemType.MainCategory) return (
        <>
            <MainCategory key={Item.Id} Item={Item} />
            {
                Item.Items.map(i => <MenuItem key={i.Id} Item={i} openProductDetails={openProductDetails} />)
            }
        </>
    );

    if (Item.Type === ItemType.SubCategory) return (
        <>
            <SubCategory key={Item.Id} item={Item} />
            {
                Item.Items.map(i => <MenuItem key={i.Id} Item={i} openProductDetails={openProductDetails} />)
            }
        </>
    );

    if (Item.Type === 3) return (<MenuItemRaised key={Item.Id} Item={Item} openProductDetails={openProductDetails} />);
    if (Item.Type === 4) return (<MenuItemFlat key={Item.Id} Item={Item} openProductDetails={openProductDetails} />);
    if (Item.Type === 5) return (<MenuItemWithCart key={Item.Id} Item={Item} openProductDetails={openProductDetails} />);
    if (Item.Type === 6) return (<MenuItemDetailed key={Item.Id} Item={Item} openProductDetails={openProductDetails} />);
    if (Item.Type === 7) return (<MenuItemRaisedRTL key={Item.Id} Item={Item} openProductDetails={openProductDetails} />);
    if (Item.Type === 8) return (<MenuItemFlatRTL key={Item.Id} Item={Item} openProductDetails={openProductDetails} />);
}

export default MenuItem;
