const Messages = [
    {
        MessageType: 'systemerror',
        Language: 1,
        Caption: 'Bir hata oluştu!',
        Title: 'Lütfen tekrar deneyin'
    },
    {
        MessageType: 'systemerror',
        Language: 2,
        Caption: 'An unexpected error occured!',
        Title: 'Please try again'
    },
    {
        MessageType: 'systemerror',
        Language: 3,
        Caption: 'Произошла непредвиденная ошибка',
        Title: 'Пожалуйста, попробуйте еще раз'
    },
    {
        MessageType: 'systemerror',
        Language: 4,
        Caption: 'حدث خطأ غير متوقع',
        Title: 'حاول مرة اخرى '
    },
    {
        MessageType: 'brandchurned',
        Language: 1,
        Caption: 'Bu link geçersiz.',
        Title: 'Lütfen daha sonra tekrar deneyin'
    },
    {
        MessageType: 'brandchurned',
        Language: 2,
        Caption: 'This link has expired.',
        Title: 'Please try again later'
    },
    {
        MessageType: 'brandchurned',
        Language: 3,
        Caption: 'Срок действия этой ссылки истек.',
        Title: 'Пожалуйста, попробуйте позже'
    },
    {
        MessageType: 'brandchurned',
        Language: 4,
        Caption: 'انتهت صلاحية هذا الرابط',
        Title: 'حاول مرة اخرى لاحقا'
    }
];

export function getLocalizedMessage(messagetype, language) {
    return Messages.filter(m => m.MessageType === messagetype && m.Language === language)[0];
}