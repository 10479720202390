import React, { useContext } from 'react'
import { Language } from './../../globals';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const useStyles = makeStyles(() => ({
    switchmerchant: {
        width: '100%',
        height: '50px',
        minHeight: '50px',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        boxSizing: 'border-box',
    },
    merchant: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    switch: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const SwitchMerchant = () => {
    const classes = useStyles();
    const { Config, Merchants, selectMerchant, SelectedMenuSet, SelectedMerchant } = useContext(DataContext);
    const showswitchbox = (Merchants[1] && (Config.ShowMerchantList && SelectedMerchant));

    if (showswitchbox)
        return (
            <div className={classes.switchmerchant} >
                <div className={classes.content}>
                    <div className={classes.merchant}>
                        <Typography variant='caption' color='textPrimary' style={{ paddingRight: '8px' }}>{(SelectedMenuSet.Language === Language.Turkish) ? "Seçili Restoran:" : "Selected Restaurant:"}</Typography>
                        <Typography variant='caption' color='primary'>{SelectedMerchant.Name}</Typography>
                    </div>
                    <div className={classes.switch} onClick={() => selectMerchant(null)}>
                        <Typography variant='caption' color='primary'>{(Language === 1) ? "Değiştir" : "Go Back"}</Typography>
                        <ArrowRightIcon color='primary' />
                    </div>
                </div>
                <Divider />
            </div>
        );
    else
        return '';
}

export default SwitchMerchant