import React, { useContext, useEffect, useState } from 'react'

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

// Components
import Customer from './customer.page';

// Contexts
import { DataContext } from '../_contexts/data.context';
import { CartContext } from '../_contexts/cart.context';

const useStyles = makeStyles(() => ({
    cart: {
        width: '100%',
        height: '100%',
        maxWidth: '440px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
    },
    downbutton: {
        position: 'absolute',
        left: '12px',
        top: '12px',
        backgroundColor: '#fafafa',
        opacity: '0.75',
        zIndex: '1500',
    },
    logo: {
        padding: '8px',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    item: {
        padding: '8px',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    cartitems: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    cartitem: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100px',
        minHeight: '100px',
        boxSizing: 'border-box',
    },
    cartitemtitle: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '48px',
    },
    cartitemactions: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '36px',
        boxSizing: 'border-box',
    },
    fab: {
        position: 'fixed',
        width: '80%',
        maxWidth: '400px',
        right: '10%',
        bottom: '24px',
    },
    progress: {
        paddingLeft: '12px',
        paddingRight: '12px',
        height: '24px',
    },
    spacer: {
        width: '100%',
        maxWidth: '440px',
        height: '90px',
        minHeight: '90px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />;
});

function CartItem(props) {
    const classes = useStyles();
    const { Item } = props;
    const { removeItemFromCart, incrementQuantity, decrementQuantity, cartStatus } = useContext(CartContext);

    const options = Item.Options && JSON.parse(Item.Options);

    return (
        <div key={Item.Id} className={classes.cartitem}>
            <div className={classes.cartitemtitle}>
                <Typography variant='subtitle1' color='textPrimary' style={{ flexGrow: 1, textAlign: 'left', padding: '0 12px 0 12px' }}>{Item.Title}</Typography>
                {
                    options &&
                    <Typography variant='caption' color='textPrimary' style={{ flexGrow: 1, textAlign: 'left', padding: '0 12px 0 12px' }}>{options[0].Items[0].Title}</Typography>
                }
                {
                    (cartStatus.action === 'removing item') && (cartStatus.id === Item.Id)
                        ?
                        <div className={classes.progress}><CircularProgress size={24} color='primary' /></div>
                        :
                        <IconButton color='primary' aria-label="delete" size="medium" onClick={() => removeItemFromCart(Item.Id)}>
                            <DeleteOutlineOutlinedIcon fontSize="inherit" />
                        </IconButton>
                }
            </div>
            <div className={classes.cartitemactions}>
                <>
                    {
                        (cartStatus.action === 'increment item') && (cartStatus.id === Item.Id)
                            ?
                            <div className={classes.progress}><CircularProgress size={24} color='primary' /></div>
                            :
                            <IconButton color='primary' aria-label="add" size="medium" onClick={() => incrementQuantity(Item.Id)}>
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                    }
                    <Typography color='textPrimary' variant='body1' >{Item.Quantity}</Typography>
                    {
                        (cartStatus.action === 'decrement item') && (cartStatus.id === Item.Id)
                            ?
                            <div className={classes.progress}><CircularProgress size={24} color='primary' /></div>
                            :
                            <IconButton color='primary' aria-label="remove" size="medium" onClick={() => decrementQuantity(Item.Id)}>
                                <RemoveIcon fontSize="inherit" />
                            </IconButton>
                    }
                    {
                        Item.Price &&
                        <Typography variant='caption' color='textSecondary'>{`(${Item.Price} ₺)`}</Typography>
                    }
                </>
                <Typography color='primary' variant='h6' style={{ textAlign: 'right', flexGrow: 1, paddingRight: '12px' }}>{`${Item.Amount} ₺`}</Typography>
            </div>
            <Divider />
        </div>
    );
}

export default function Cart(props) {
    const classes = useStyles();
    const [customerOpen, setCustomerOpen] = useState(false);
    const { closeCart } = props;
    const { SelectedMenuSet } = useContext(DataContext);
    const { Cart, ItemCount, TotalPrice } = useContext(CartContext);

    const openCustomer = () => setCustomerOpen(true);
    const closeCustomer = () => setCustomerOpen(false);

    useEffect(() => {
        if (ItemCount === 0) {
            const timeout = setTimeout(closeCart, 600);
            return () => clearTimeout(timeout);
        };
    }, [ItemCount, closeCart]);

    return (
        <div className={classes.cart}>
            <IconButton aria-label="back" color='primary' className={classes.downbutton} onClick={closeCart}>
                <ExpandMoreIcon />
            </IconButton>
            <div className={classes.logo}><img src={SelectedMenuSet.LogoUrl} alt='Brand' /></div>
            <div className={classes.item}><Typography color='primary' variant='body1'>{`Toplam: ${TotalPrice} ₺`}</Typography></div>
            <div className={classes.cartitems}>
                {
                    Cart.Items.map(item => <CartItem
                        key={item.Id}
                        Item={item}
                    />)
                }
                <div className={classes.spacer} />
            </div>
            <div className={classes.fab}>
                <Fab color="primary" aria-label="add" variant="extended" style={{ width: '100%', }} onClick={openCustomer}>{`${TotalPrice} ₺  -  SİPARİŞ VER`}</Fab>
            </div>
            <Dialog fullScreen open={customerOpen} onClose={closeCustomer} TransitionComponent={Transition}>
                <Customer closeCustomer={closeCustomer} />
            </Dialog>
        </div>
    );
}