import React, { Fragment, useContext, useState } from 'react'
import { ProductOptionType } from '../globals';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

// Components
import Price from '../_components/price.component';

// Contexts
import { CartContext } from '../_contexts/cart.context';
import { DataContext } from '../_contexts/data.context';

const useStyles = makeStyles(theme => ({
    product: {
        width: '100%',
        height: '100%',
        minWidth: "350px",
        minHeight: "100%",
        maxWidth: '440px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
        position: 'relative',
    },
    image: {
        width: '100%',
    },
    item: {
        padding: '8px',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    topspacer: {
        width: '100%',
        height: '80px',
        minHeight: '80px',
    },
    backbuttonbackground: {
        position: 'absolute',
        left: '12px',
        top: '12px',
        backgroundColor: theme.palette.background.paper,
    },
    backbutton: {
        position: 'absolute',
        left: '12px',
        top: '12px',
    },
    extras: {
        width: '100%',
        paddingRight: '26px',
        paddingLeft: '26px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    quantityitem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px',
        width: '100%',
        boxSizing: 'border-box',
    },
    extraitem: {
        display: 'flex',
        alignItems: 'center',
        padding: '2px',
        width: '100%',
        boxSizing: 'border-box',
    },
    extratitle: {
        padding: '4px',
        textAlign: 'left',
        width: '100%',
        boxSizing: 'border-box',
    },
    rating: {
        padding: '8px 16px 8px 8px',
        width: '100%',
        boxSizing: 'border-box',
        textAlign: 'right',
    },
    buttonProgress: {
        color: theme.palette.background.paper,
        position: 'absolute',
        top: '50%',
        left: '30%',
        marginTop: -12,
        marginLeft: -14,
    },
    fab: {
        position: 'fixed',
        width: '80%',
        right: '10%',
        bottom: '24px',
    },
    spacer: {
        width: '100%',
        height: '80px',
        minHeight: '80px',
    }
}));

export default function Product(props) {
    const classes = useStyles();
    const { Product, closeProductDetails } = props;
    const { Config, Credentials } = useContext(DataContext);
    const { addItemToCart, cartStatus } = useContext(CartContext);

    // Enrich the Product object with some calculations
    Product['productHasOptionsWithPrice'] = (Product.Options && (Product.Options.find(o => o.Type === ProductOptionType.WithPrice) !== undefined));
    Product['productHasOptions'] = (Product.Options && (Product.Options.length > 0));
    Product['productHasExtras'] = (Product.Extras && (Product.Extras.length > 0));
    Product['calculatedInitialPrice'] = Product.productHasOptionsWithPrice
        ? Product.Options.find(o => o.Type === ProductOptionType.WithPrice).Items[0].Price
        : Product.Price;


    const [itemToAdd, setItemToAdd] = useState({
        'Title': Product.Title,
        'ProductId': Product.ProductId,
        'Quantity': 1,
        'CurrencyId': Credentials.SelectedCurrencyId,
        'Price': Product.calculatedInitialPrice,
        'Options': Product.Options.map(o => { return { Title: o.Title, Type: o.Type, Items: o.Items.length > 0 ? [o.Items[0]] : [] } }),
        'Extras': Product.Extras.map(e => { return { Title: e.Title, Type: e.Type, Items: [] } }),
    });

    const incrementQuantity = () => {
        setItemToAdd(prevState => {
            return {
                ...prevState,
                Quantity: prevState.Quantity + 1
            }
        });
    };

    const decrementQuantity = () => {
        (itemToAdd.Quantity > 1) &&
            setItemToAdd(prevState => {
                return {
                    ...prevState,
                    Quantity: prevState.Quantity - 1
                }
            });
    }

    const handleOptionChange = (option, optionitem) => {
        setItemToAdd(prevState => {
            return {
                ...prevState,
                Price: (option.Type === ProductOptionType.WithPrice
                    ? optionitem.Price
                    : (Product.Price ? Product.Price : prevState.Price))
                    + prevState.Extras.reduce((sum, extra) => sum + extra.Items.reduce((s, e) => s + e.Price, 0), 0),
                Options: prevState.Options.map(o => {
                    return (o.Title === option.Title)
                        ? { Title: o.Title, Type: o.Type, Items: [optionitem] }
                        : o
                })
            }
        });
    }

    const getOptionChecked = (option, optionitem) => {
        return itemToAdd.Options.filter(o => o.Title === option.Title)[0].Items[0].Id === optionitem.Id;
    }

    if (Object.keys(Product).length === 0 && Product.constructor === Object) return '';

    return (
        <div className={classes.product} >
            <IconButton aria-label="back" className={Product.ImageUrl ? classes.backbuttonbackground : classes.backbutton} onClick={closeProductDetails}>
                <ArrowBackIcon color='secondary' />
            </IconButton>
            {
                Product.ImageUrl
                    ?
                    <div><img className={classes.image} src={Product.ImageUrl} alt='Brand' /></div>
                    :
                    <div className={classes.topspacer} />
            }
            <div className={classes.item}><Typography variant='h5' color='textPrimary'>{Product.Title}</Typography></div>
            {
                Product.SubTitle &&
                <div className={classes.item}><Typography variant='body1' color='textSecondary'>{Product.SubTitle}</Typography></div>
            }
            <div className={classes.item}><Price Item={itemToAdd} large calculateTotal /></div>
            {
                (Config.GetOrder) &&
                <div className={classes.item}>
                    <div className={classes.extras}>
                        <div className={classes.extratitle}>
                            <Typography variant='caption' color='textSecondary'>Adet</Typography>
                        </div>
                        <div key={Product.Id} className={classes.quantityitem}>
                            <Fab size="small" color="primary" aria-label="add" className={classes.margin} onClick={() => incrementQuantity()}>
                                <AddIcon />
                            </Fab>
                            <Typography variant='h6' color='primary' style={{ width: '50px' }}> {itemToAdd.Quantity}</Typography>
                            <Fab size="small" color="primary" aria-label="add" className={classes.margin} onClick={() => decrementQuantity()}>
                                <RemoveIcon />
                            </Fab>
                        </div>
                    </div>
                </div>
            }
            {
                Product.Description &&
                <div className={classes.item}><Typography variant='body1' color='textSecondary'>{Product.Description}</Typography></div>
            }
            {
                (Product.Options.length > 0) &&
                <div className={classes.item}>
                    <div className={classes.extras}>
                        {
                            Product.Options.map((option, index) => (
                                < Fragment key={index}>
                                    <div key={index} className={classes.extratitle}>
                                        <Typography variant='caption' color='textSecondary'>{option.Title}</Typography>
                                    </div>
                                    {
                                        option.Items.map(optionitem => (
                                            <div key={optionitem.Id} className={classes.extraitem}>
                                                {
                                                    (Config.Pickup || Config.Delivery) &&
                                                    <Radio
                                                        checked={getOptionChecked(option, optionitem)}
                                                        onChange={() => handleOptionChange(option, optionitem)}
                                                        value={optionitem.Id}
                                                        name={optionitem.Title}
                                                        inputProps={{ 'aria-label': optionitem.Title }}
                                                    />
                                                }
                                                <div onClick={() => { Config.GetOrder && handleOptionChange(option, optionitem) }} style={{ textAlign: 'left', flexGrow: 1 }}>
                                                    <Typography color='textSecondary' variant='body1'>{optionitem.Title}</Typography>
                                                </div>
                                                <Price Item={optionitem} />
                                            </div>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            }
            {
                (Product.Extras.length > 0) &&
                <div className={classes.item}>
                    <div className={classes.extras}>
                        {
                            Product.Extras.map((extra, index) => (
                                <Fragment key={index}>
                                    <div key={index} className={classes.extratitle}>
                                        <Typography variant='caption' color='textSecondary'>{extra.Title}</Typography>
                                    </div>
                                    {
                                        extra.Items.map(item => (
                                            <div key={item.Id} className={classes.extraitem}>
                                                <div style={{ textAlign: 'left', flexGrow: 1 }}><Typography color='textSecondary' variant='body1'>{item.Title}</Typography></div>
                                                <Price Item={item} />
                                            </div>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            }
            {
                (Config.GetOrder) &&
                ((cartStatus.action === 'item added' && cartStatus.id === itemToAdd.ProductId)
                    ?
                    <div className={classes.fab}>
                        <Fab color="primary" aria-label="add" variant="extended" style={{ width: '100%', }}>SEPETE EKLENDİ</Fab>
                    </div>
                    :
                    <div className={classes.fab} >
                        <Fab color="primary" onClick={() => addItemToCart(itemToAdd)} variant="extended" style={{ width: '100%', }}>SEPETE EKLE</Fab>
                        {(cartStatus.action === 'adding item') && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                )
            }
            {
                (Product.Extras.length > 0 || Product.Options.length > 0) &&
                <div className={classes.spacer}></div>
            }
        </div >
    );
}