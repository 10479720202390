import React, { useContext } from 'react'

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/ChatBubbleOutline';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// Contexts
import { DataContext } from '../_contexts/data.context';

const useStyles = makeStyles(theme => ({
    topmenu: {
        width: '100%',
        maxWidth: '440px',
        height: '50px',
        minHeight: '50px',
        padding: '0px 24px 0px 8px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default,
    },
    selected: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    feedback: {
        float: 'left',
    },
    breadcrumb: {
        float: 'right',
        paddingTop: '10px',
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.text.secondary,
    },
}));

const ToolBar = () => {
    const classes = useStyles();
    const { Config, Languages, setSelectedLanguage } = useContext(DataContext);
    const showtoolbar = (Config.Feedback || Languages.Count > 1);

    if (showtoolbar)
        return (
            <div className={classes.topmenu}>
                {
                    Config.Feedback &&
                    <IconButton aria-label="back" color='primary' className={classes.feedback}>
                        <CommentIcon />
                    </IconButton>
                }
                {
                    (Languages.Count > 1) &&
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb} >
                        {
                            Languages.Codes.map((lang, i) =>
                                (i === Languages.Index)
                                    ?
                                    <Typography key={i} variant='body1' className={classes.selected} >{lang}</Typography>
                                    :
                                    <Link key={i} variant='body1' className={classes.link} onClick={() => setSelectedLanguage(Languages.Ids[i])}>{lang}</Link>
                            )
                        }
                    </Breadcrumbs>
                }
            </div>
        );
    else
        return '';
}

export default ToolBar;