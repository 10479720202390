import React, { Fragment, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    consentText: {
        margin: "auto 0",
        color: theme.palette.primary.main,
        maxWidth: "85%",
        textDecoration: "underline",
        cursor: 'pointer',
    }
}));

function Consent(props) {
    const classes = useStyles();
    const { Name, Title, TermsOfUse, useInput, isSubmitting, helperText } = props;
    const [open, setOpen] = useState(false);

    const { value, onChange, onFocus, onBlur, error, required } = useInput(Name, true, 'consent');

    return (
        <Fragment>
            <FormGroup aria-label="position" row>
                <Checkbox name={Consent.Name} color="primary" disabled={isSubmitting} required={required} onChange={onChange} onFocus={onFocus} onBlur={onBlur} value={value} />
                <Typography disabled={isSubmitting} className={classes.consentText} variant='subtitle2' onClick={() => { setOpen(true) }}>{Title}</Typography>
            </FormGroup>
            {
                error && <FormHelperText error disabled={isSubmitting} style={{ paddingLeft: '15px' }}>{helperText}</FormHelperText>
            }
            <Dialog fullScreen open={open} onClose={() => { setOpen(false) }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{Title}</DialogTitle>
                <DialogContent>
                    <DialogContentText variant='body2'>{TermsOfUse}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false) }} color="primary">TAMAM</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default Consent;