import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Merchant from '../_components/merchant/merchant.component';

import { DataContext } from '../_contexts/data.context';

const useStyles = makeStyles(theme => ({
    wellcome: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
    },
    item: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    spacer: {
        display: 'block',
        width: '100%',
        height: '50px',
        minHeight: '50px',
    }
}));

function Wellcome() {
    const classes = useStyles();
    const { Theme, Merchants } = useContext(DataContext);
    const WellcomePage = Theme && Theme.WellcomePage;

    return (
        <div className={classes.wellcome}>
            <div className={classes.spacer} />
            {
                WellcomePage &&
                WellcomePage.LogoUrl &&
                <div className={classes.item}><img src={WellcomePage.LogoUrl} alt='Wellcome Logo' /></div>
            }
            {
                WellcomePage &&
                WellcomePage.Title &&
                <div className={classes.item}><Typography color='primary' variant='h6'>{WellcomePage.Title}</Typography></div>
            }
            {
                WellcomePage &&
                WellcomePage.Subtitle &&
                <div className={classes.item}><Typography color='primary' variant='caption'>{WellcomePage.Subtitle}</Typography></div>
            }
            {
                WellcomePage &&
                WellcomePage.WellcomeText &&
                <div className={classes.item}><Typography color='textPrimary' variant='body1'>{WellcomePage.WellcomeText}</Typography></div>
            }
            {
                WellcomePage &&
                WellcomePage.Description &&
                <div className={classes.item}><Typography color='textSecondary' variant='body2'>{WellcomePage.Description}</Typography></div>
            }
            {
                Merchants &&
                Merchants.map(merchant => <Merchant key={merchant.Id} Merchant={merchant} />)
            }
            {
                WellcomePage &&
                WellcomePage.Footer &&
                <div className={classes.item}><Typography color='textSecondary' variant='caption'>{WellcomePage.Footer}</Typography></div>
            }
        </div>
    );
}

export default Wellcome;