export const Language = {
    Turkish: 1,
    English: 2,
    Russian: 3,
    Arabic: 4,
}

export const ItemType = {
    MainCategory: 1,
    SubCategory: 2,
    Raised: 3,
    Flat: 4,
    WithCart: 5,
    Detailed: 6,
    RaisedRTL: 7,
    FlatRTL: 8,
}

export const FooterType = {
    Instagram: 1,
    Twitter: 2,
    Facebook: 3,
    Snapchat: 4,
    Whatsapp: 5,
    Map: 6,
    Clock: 7,
    Phone: 8,
    Info: 9,
}

export const CurrencyType = {
    TRY: 1,
    USD: 2,
    EURO: 3,
    POUND: 4,
    AED: 5,
    RUB: 6,
    QAR: 7,
    SR: 8,
    OMR: 9,
    KWD: 10,
    EGP: 11,
    BHD: 13,
    JD: 14,
    DKK: 15,
    CAD: 16,
    AZN: 17
}

export const MenuLayout = {
    Carousel: 1,
    Tile: 2,
}

export const ProductOptionType = {
    FreeOfCharge: 1,
    WithPrice: 2,
}