import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
        position: 'relative',
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fafafa',
    },
    content: {
        height: '80px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '4px 0px 4px 10px',
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    subtitle: {
        width: '100%',
    },
    price: {
        paddingRight: '10px',
    },
    image: {
        height: '80px',
        width: '80px',
        borderRadius: '4px 0 0 4px',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));


function MenuItemDetailed(props) {
    const classes = useStyles();

    const { item, openProductDetails } = props;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3} onClick={() => openProductDetails(item)}>
                <img className={classes.image} alt={item.Title} src={item.ThumbnailUrl} />
                <div className={classes.content}>
                    <div className={classes.title}><Typography variant='body1'>{item.Title}</Typography></div>
                    {
                        item.SubTitle && <div className={classes.subtitle}><Typography variant='body2' color='primary'>{item.SubTitle}</Typography></div>
                    }
                </div>
                {
                    item.Price && <div className={classes.price}><Typography color='primary' variant='h6'>{item.Price ? '₺' + item.Price : ''}</Typography></div>
                }
            </Paper>
        </div >
    );
}

export default MenuItemDetailed;
