import React from 'react'

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Components
import Price from '../../price.component';

const useStyles = makeStyles(() => ({
    raiseditem: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        height: '80px',
        minHeight: '80px',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '4px 6px 4px 10px',
        justifyContent: 'center',
    },
    image: {
        height: '80px',
        minHeight: '80px',
        width: '80px',
        minWidth: '80px',
        borderRadius: '4px 0 0 4px',
    },
    title: {
        width: '100%',
    },
    subtitle: {
        width: '100%',
    },
    spacer: {
        width: '24px',
        height: '24px',
        minWidth: '24px',
        minHeight: '24px',
    },
}));

function MenuItemRaised(props) {
    const classes = useStyles();
    const { Item, openProductDetails } = props;

    const openDetails = () => {
        if (!Item.NoDetails) openProductDetails(Item)
    }

    return (
        <div className={classes.raiseditem}>
            <Paper className={classes.paper} elevation={3} onClick={openDetails}>
                {
                    Item.ThumbnailUrl &&
                    <img className={classes.image} alt={Item.Title} src={Item.ThumbnailUrl} />
                }
                <div className={classes.content}>
                    <div className={classes.title}><Typography color='textPrimary' variant='body2'>{Item.Title}</Typography></div>
                    {
                        Item.SubTitle &&
                        <div className={classes.subtitle}><Typography variant='caption' color='textSecondary'>{Item.SubTitle}</Typography></div>
                    }
                </div>
                <Price Item={Item} />
                {
                    (Item.NoDetails)
                        ?
                        <div className={classes.spacer} />
                        :
                        <ArrowRightIcon color='primary' />
                }
            </Paper>
        </div >
    );
}

export default MenuItemRaised;
