import React, { useContext } from 'react'
import { MenuLayout } from '../globals';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';

// Components
import CarouselMenu from '../_components/menu/carousel.menu';
import CartFab from '../_components/cart/cartfab.component';
import SwitchMerchant from '../_components/merchant/switchmerchant.component';
import TileMenu from '../_components/menu/tile.menu';
import ToolBar from '../_components/toolbar.component';

// Contexts
import { DataContext } from '../_contexts/data.context';

const useStyles = makeStyles(() => ({
    menuset: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
    },
}));

const Menu = () => {
    const classes = useStyles();
    const { SelectedMenuSet } = useContext(DataContext);

    return (
        <div className={classes.menuset}>
            <SwitchMerchant />
            <ToolBar />
            {
                (SelectedMenuSet && SelectedMenuSet.Layout && SelectedMenuSet.Layout === MenuLayout.Tile)
                    ?
                    <TileMenu />
                    :
                    <CarouselMenu />
            }
            <CartFab />
        </div>
    );
}

export default Menu;