import React, { useContext, useState, forwardRef } from 'react'
import { Language } from '../../globals';
import { isMobileOnly } from 'react-device-detect';
import { ItemType } from '../../globals';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

// Components
import Carousel from './carousel.component'
import Footer from './footer.component';
import MenuItem from './item.component'
import Product from '../../_pages/product.page';

// Contexts
import { DataContext } from '../../_contexts/data.context';

// Transition
const TransitionLeft = forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />;
});

const TransitionRight = forwardRef(function Transition(props, ref) {
    return <Slide direction='right' ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    item: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
}));

const CarouselMenu = () => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedItem, setSelectedItem] = useState({});
    const [pageOpen, setPageOpen] = useState(false);
    const { SelectedMenuSet, Theme } = useContext(DataContext);

    const Transition = SelectedMenuSet.Language === Language.Arabic ? TransitionRight : TransitionLeft;

    const items = SelectedMenuSet.Menu.filter(item => item.Type === ItemType.MainCategory || item.Type === ItemType.SubCategory);

    const openProductDetails = (item) => { setSelectedItem(item); setPageOpen(true); }
    const closeProductDetails = () => setPageOpen(false);

    function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186;
    }
    let isWhiteBg = pickTextColorBasedOnBgColorSimple(Theme?.BgColor) || false;

    return (
        <>
            {
                SelectedMenuSet.LogoUrl &&
                <div className={classes.item}><img src={SelectedMenuSet.LogoUrl} alt='Brand' /></div>
            }
            {
                SelectedMenuSet.Title &&
                <div className={classes.item}><Typography color='textPrimary' variant='h5'>{SelectedMenuSet.Title}</Typography></div>
            }
            {
                SelectedMenuSet.Description &&
                <div className={classes.item}><Typography variant='body1' color='textSecondary'>{SelectedMenuSet.Description}</Typography></div>
            }
            {
                items.length > 0 &&
                <Carousel Items={SelectedMenuSet.Menu} selected={selectedTab} setSelected={setSelectedTab} />
            }
            <div style={{ maxWidth: '440px', width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                {
                    items[selectedTab].Items.map(i => <MenuItem key={i.Id} Item={i} openProductDetails={openProductDetails} />)
                }
            </div>
            {
                SelectedMenuSet.Footer &&
                <Footer Footer={SelectedMenuSet.Footer} />
            }
            <div style={{ height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '16px' }}>
                <Typography color='textPrimary' variant='caption' style={{ textAlign: 'center', marginRight: '8px', }}>
                    Powered by
                </Typography>
                <img src={`assets/img/${isWhiteBg ? 'bw_black.png' : 'bw_white.png'}`} alt='bw-logo' />
            </div>
            <Dialog fullScreen={isMobileOnly} open={pageOpen} onClose={closeProductDetails} TransitionComponent={Transition}>
                <Product Product={selectedItem} closeProductDetails={closeProductDetails} />
            </Dialog>
        </>
    );
}

export default CarouselMenu;