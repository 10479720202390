import { useState, useCallback, useEffect } from 'react'

export function useFormInput({
    name,
    isrequired,
    type,
    helpertext,
    values: formData,
    setValues: setFormData,
    handleError,
    formSubmitting,
    setSubmitting
}) {
    const formValue = formData[name] || '';

    const [value, setValue] = useState(formValue);
    const [isValid, setIsValid] = useState(true);
    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleValidation = useCallback(() => {
        const checkvalid = validate(value, isrequired, type);
        setIsValid(checkvalid);
        handleError(name, checkvalid);
    }, [setIsValid, name, value, isrequired, type, handleError]);

    // watch for external parent data changes
    useEffect(() => {
        if (value !== formValue) {
            setValue(formValue);
            setIsTouched(false);
            setIsFocused(false);
        }
    }, [formValue, value, setValue, setIsFocused, setIsTouched]);

    // validate on value change
    useEffect(() => {
        handleValidation();
    }, [handleValidation, name]);

    // rewrite self and parent's value
    const handleChange = useCallback((event, value) => {
        let newValue;

        if (typeof event === 'object') {
            newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        }
        else {
            newValue = event;
        }

        let data = { ...formData, [name]: newValue };

        setSubmitting(false);
        setValue(newValue);
        setFormData(data);
    }, [setValue, formData, setFormData, setSubmitting, name]);

    const handleFocus = useCallback(() => {
        setIsTouched(true);
        setIsFocused(true);
        handleValidation();
    }, [setIsTouched, setIsFocused, handleValidation]);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    const error = formSubmitting ? !isValid : !isValid && isTouched && !isFocused;

    return {
        name,
        value,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        error,
        required: isrequired,
        helperText: error ? helpertext : ' ',
    };
}

export function validate(value, isRequired, type) {
    if (isRequired && !value) return false;

    if (type === 'email') {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }
    if (type === 'phone') {
        return value.trim().length < 10 ? false : true;
    }

    return true;
}