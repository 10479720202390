export const getUserAgent = () => navigator.userAgent;
export const getHash = () => window.location.pathname.substr(1);
export const toCapitalCase = (str) => str.split(' ').map(i => i[0].toUpperCase() + i.substring(1).toLowerCase()).join(' ');
export const getBWId = () => localStorage.getItem('BWId');
export const setBWId = (BWId) => { if (BWId !== null) localStorage.setItem('BWId', BWId); }
export const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
export const getGUID = () => (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
export const getCustomer = () => JSON.parse(localStorage.getItem('customer')) || {};
export const saveCustomer = (Customer) => localStorage.setItem('customer', JSON.stringify({ FullName: Customer.FullName, Phone: Customer.Phone, Email: Customer.Email, Address: Customer.Address }));

export const getLanguages = (MenuSets, selectedLanguage) => {
    var Ids = MenuSets.map(menu => parseInt(menu.Language));
    var LanguageCodes = Ids.map(Lang => getLanguageCode(Lang));
    var Count = MenuSets.length;
    var Index = Ids.indexOf(selectedLanguage);

    return {
        Ids: Ids,
        Codes: LanguageCodes,
        Count: Count,
        Index: Index,
        Language: selectedLanguage
    }
}

export const getInitialLanguage = (MenuSets, BrowserLanguage) => {
    var Languages = MenuSets.map(menu => parseInt(menu.Language)); // Get all the languages from the forms list
    if (Languages.includes(BrowserLanguage)) return BrowserLanguage; // If the browser language exits in the list, return that one
    return Languages[0] // If browser language is not in the list, return the language of first form
}

export const getBrowserLanguage = () => {
    const lang = navigator.language || navigator.userLanguage;

    if (lang === 'tr-TR')
        return 1;
    if (lang === 'ru-RU')
        return 3;
    if (lang.startsWith('ar'))
        return 4;

    return 2;  // If the browser does not have any of the languages above, return English
}

export const getLanguageCode = (language) => {
    if (language === 2)
        return 'EN';
    if (language === 1)
        return 'TR';
    if (language === 3)
        return 'RU';
    if (language === 4)
        return 'عربى';
    if (language === 6)
        return 'FR';
    if (language === 7)
        return 'AZ';
}

export const getLanguageFromCode = (language) => {
    if (language === 'EN')
        return 2;
    if (language === 'TR')
        return 1;
    if (language === 'RU')
        return 3;
    if (language === 'AR')
        return 4;
    if (language === 'FR')
        return 6;
    if (language === 'AZ')
        return 7;
}
