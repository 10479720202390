import React, { useContext } from 'react';

// Material Ui
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Pages
import Error from './_pages/error.page';
import Menu from './_pages/menu.page';
import Orders from './_pages/orders.page';
import Spinner from './_pages/spinner.page';
import Success from './_pages/success.page';
import Wellcome from './_pages/wellcome.page';

// Contexts
import { DataContext } from './_contexts/data.context';
import { CartContext } from './_contexts/cart.context';

// Helpers
import { getBrowserLanguage, getHash } from './_helpers/helperfunctions';

function App() {
    const { fetchingConfig, fetchError, fetchingMenu, Config, SelectedMerchant, SelectedMenuSet, Theme } = useContext(DataContext);
    const { cartStatus, fetchingCart } = useContext(CartContext);

    if (getHash() === 'orders') return <Orders />
    if (fetchError) return <Error ReturnCode={fetchError} Language={getBrowserLanguage()} />;
    if (fetchingConfig || fetchingCart) return <Spinner />;
    if (cartStatus.action === 'checkout successful') return <Success />;

    // Create a theme with the color from the menu configuration
    const theme = createMuiTheme({
        palette: {
            type: 'light',
            background: { default: Theme.BgColor, paper: Theme.BgColor },
            primary: { main: Theme.PrimaryColor, },
            secondary: { main: Theme.SecondaryColor, },
            error: { main: '#d84315' },
            text: { primary: Theme.TextPrimaryColor, secondary: Theme.TextSecondaryColor, },
        },
        typography: {
            body1: {
                fontSize: 18,
                '@media (max-width:360px)': { fontSize: 15, },
                '@media (max-width:320px)': { fontSize: 14, },
                fontWeight: '400',
            },
            body2: {
                fontSize: 14,
                '@media (max-width:360px)': { fontSize: 13, },
                '@media (max-width:320px)': { fontSize: 12, },
            },
            h6: {
                fontSize: '1.25rem',
                '@media (max-width:360px)': { fontSize: '1rem', },
                '@media (max-width:320px)': { fontSize: '0.85rem', },
                fontWeight: '400',
            },
        },
    });

    return (
        < ThemeProvider theme={theme} >
            <CssBaseline />
            {(() => {
                if (Config.ShowMerchantList && SelectedMerchant === null) return <Wellcome />;
                if (fetchingMenu) return < div></div >;
                if (SelectedMenuSet) return <Menu />;
            })()}
        </ThemeProvider >
    );
}

export default App;