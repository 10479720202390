import React, { useContext } from 'react'

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Components
import Consent from '../_components/consent.component';

// Contexts
import { DataContext } from '../_contexts/data.context';
import { CartContext } from '../_contexts/cart.context';

// Helpers
import { useForm } from '../_forms/useForm';

const useStyles = makeStyles(theme => ({
    customer: {
        width: '100%',
        height: '100%',
        maxWidth: '440px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
    },
    caption: {
        width: '100%',
        padding: '60px 8px 8px 8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    formitem: {
        width: '100%',
        padding: '0 8px 0px 8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    consentText: {
        margin: "auto 0",
        color: theme.palette.primary.main,
        maxWidth: "85%",
        textDecoration: "underline",
        cursor: 'pointer',
    },
    backbutton: {
        position: 'absolute',
        left: '12px',
        top: '12px',
        backgroundColor: '#fafafa',
        opacity: '0.75',
        zIndex: '1500',
    },
    addtobasket: {
        position: 'fixed',
        width: '80%',
        maxWidth: '400px',
        right: '10%',
        bottom: '24px',
    },
    topspacer: {
        width: '100%',
        height: '60px',
    },
    spacer: {
        width: '100%',
        maxWidth: '440px',
        height: '90px',
        minHeight: '90px',
    },
    buttonProgress: {
        color: theme.palette.background.paper,
        position: 'absolute',
        top: '50%',
        left: '30%',
        marginTop: -12,
        marginLeft: -46,
        zIndex: 2000,
    },
}));

function Customer(props) {
    const classes = useStyles();
    const { GDPRContract, SalesContract } = useContext(DataContext);
    const { checkingOut, checkoutCart, Customer } = useContext(CartContext);
    const { closeCustomer } = props;
    const { values, setSubmitting, useInput, canSubmit } = useForm(Customer);

    const submitForm = () => {
        if (canSubmit) {
            checkoutCart(values);
        }
        else
            setSubmitting(true);
    }

    return (
        <div className={classes.customer}>
            <IconButton className={classes.backbutton} aria-label="back" color='primary' onClick={closeCustomer}>
                <ArrowBackIcon />
            </IconButton>
            <div className={classes.caption}><Typography color='textPrimary' variant='body2'>Lütfen siparişiniz ile ilgili olarak size ulaşabileceğimiz iletişim bilgilerinizi doldurun</Typography></div>
            <div className={classes.formitem}><TextField fullWidth id="fullname" size='small' variant='filled' label="Ad Soyad" required autoComplete='none' {...useInput('FullName', true, 'text', 'Lütfen adınızı girin')} /></div>
            <div className={classes.formitem}><TextField fullWidth id="phone" size='small' variant='filled' label="Telefon" required autoComplete='none' {...useInput('Phone', true, 'phone', 'Lütfen telefon numaranızı girin')} /></div>
            <div className={classes.formitem}><TextField fullWidth id="email" size='small' variant='filled' label="Email" required autoComplete='none' {...useInput('Email', true, 'email', 'Lütfen email adresinizi girin')} /></div>
            <div className={classes.formitem}><TextField fullWidth id="address" size='small' variant='filled' multiline rows={3} rowsMax={3} label="Adres" required autoComplete='none' {...useInput('Address', true, 'adres', 'Lütfen adresinizi adresinizi girin')} /></div>
            <div className={classes.formitem}><TextField fullWidth id="notes" size='small' variant='filled' multiline rows={3} rowsMax={3} label="Notunuz" required autoComplete='none' {...useInput('Notes', false, 'notes', '')} /></div>
            <div className={classes.formitem}>
                <Consent Name='GDPR' Title='Ön bilgilendirme formu' TermsOfUse={GDPRContract} helperText='Lütfen ön bilgilendirme formunu kabul edin' useInput={useInput} {...useInput('GDPR', true, 'adres', 'Lütfen ön bilgilendirme formunu okuyup işaretleyin')} />
            </div>
            <div className={classes.formitem}>
                <Consent Name='Communication' Title='Uzaktan satış sözleşmesi' TermsOfUse={SalesContract} helperText='Lütfen uzaktan satış sözleşmesini kabul edin' useInput={useInput} {...useInput('Communication', true, 'adres', 'Lütfen ön bilgilendirme formunu okuyup işaretleyin')} />
            </div>
            <div className={classes.spacer} />
            <div className={classes.addtobasket} >
                {
                    checkingOut &&
                    <CircularProgress size={24} className={classes.buttonProgress} />
                }
                <Fab color="primary" aria-label="add" variant="extended" style={{ width: '100%' }} onClick={submitForm}>SİPARİŞİ TAMAMLA</Fab>
            </div>
        </div>
    );
}

export default Customer;