import React, { useEffect, useContext } from 'react'
import clsx from 'clsx';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Contexts
import { CartContext } from '../_contexts/cart.context';

const useStyles = makeStyles(() => ({
    success: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    padded: {
        padding: '8px',
        textAlign: 'center',
    },
    logo: {
        width: "220px",
    }
}));

const Success = () => {
    const classes = useStyles();
    const { fetchCart } = useContext(CartContext);

    useEffect(() => {
        const fetchData = async () => {
            const timeout = setTimeout(fetchCart, 3000);
            return () => clearTimeout(timeout);
        };

        fetchData();
    });

    return (
        <div className={classes.success}>
            <img className={clsx([classes.padded, classes.logo])} src='/assets/img/motorbike.png' alt="We are on our way :)" />
            <Typography className={classes.padded} variant='body1'>{'Siparişiniz bize ulaştı.'}</Typography>
            <Typography className={classes.padded} variant='body1'>{'En kısa zamanda siparişinizi hazırlayıp yola çıkıyoruz'}</Typography>
            <Typography className={classes.padded} variant='body1'>{'Teşekkür ederiz'}</Typography>
        </div>
    );
}

export default Success;