import React, { useContext, useState } from 'react'
import { ItemType } from '../../globals';
import { isMobileOnly } from 'react-device-detect';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

// Components
import Footer from './footer.component';
import SubCategories from '../../_pages/subcategories.page';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    item: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    tilelist: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        ['@media (max-width: 361px)']: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gridGap: '10px',
        padding: '10px',
        maxWidth: '440px',
    },

    tileitem: {
        width: '100%',
        height: '120px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fafafa',
    },
    tileitemimg: {
        width: '80px',
        height: '80px',
        borderRadius: '4px 4px 0 0 ',
    },
    tileitemtitle: {
        padding: '0 4px 0 4px',
        width: '100%',
    },
}));

const TileCategory = (props) => {
    const classes = useStyles();
    const { Item, openPage } = props;

    return (
        <Paper className={classes.tileitem} elevation={3} onClick={() => openPage(Item)}>
            <img className={classes.tileitemimg} src={Item.ThumbnailUrl} alt={Item.Name} />
            <Typography className={classes.tileitemtitle} variant='caption' color='textPrimary'>{Item.Title}</Typography>
        </Paper>
    );
}

const TileMenu = () => {
    const classes = useStyles();
    const { SelectedMenuSet } = useContext(DataContext);
    const [pageOpen, setPageOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const items = SelectedMenuSet.Menu.filter(item => item.Type === ItemType.MainCategory);

    const openPage = (item) => { setSelectedItem(item); setPageOpen(true); }
    const closePage = () => setPageOpen(false);

    return (
        <>
            {
                SelectedMenuSet.LogoUrl &&
                <div className={classes.item}><img src={SelectedMenuSet.LogoUrl} alt='Brand' /></div>
            }
            {
                SelectedMenuSet.Title &&
                <div className={classes.item}><Typography color='textPrimary' variant='h5'>{SelectedMenuSet.Title}</Typography></div>
            }
            {
                SelectedMenuSet.Description &&
                <div className={classes.item}><Typography variant='body1' color='textSecondary'>{SelectedMenuSet.Description}</Typography></div>
            }
            <div className={classes.tilelist}>
                {
                    items.map(tileitem => <TileCategory key={tileitem.Id} Item={tileitem} openPage={openPage} />)
                }
            </div>
            {
                SelectedMenuSet.Footer &&
                <Footer Footer={SelectedMenuSet.Footer} />
            }
            <Dialog fullScreen={isMobileOnly} open={pageOpen} onClose={closePage} TransitionComponent={Transition}>
                <SubCategories Item={selectedItem} closePage={closePage} />
            </Dialog>
        </>
    );
}

export default TileMenu;