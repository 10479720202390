import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    item: {
        width: '100%',
        padding: '8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    title: {
        textAlign: 'left',
        paddingLeft: '16px',
    },
}));

function MainCategory(props) {
    const classes = useStyles();
    const { item } = props;

    return (
        <div className={classes.item}>
            <Typography color='primary' className={classes.title} variant='h5'>{item.Title}</Typography>
            <Divider variant="middle" />
        </div>
    );
}

export default MainCategory