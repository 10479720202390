import React, { useContext } from 'react'
import { FaSnapchatGhost, FaInstagram, FaTwitter, FaFacebookSquare, FaWhatsapp, FaMapMarkerAlt, FaClock, FaInfoCircle } from "react-icons/fa"
import { FooterType } from './../../globals';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// Contexts
import { DataContext } from '../../_contexts/data.context';

const useStyles = makeStyles(() => ({
    footer: {
        width: '100%',
        maxWidth: '440px',
        boxSizing: 'border-box',
        display: 'block',
        textAlign: 'center',
    },
    footeritem: {
        width: '100%',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    horizontalfooteritem: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spacer: {
        width: '100%',
        height: '80px',
        minHeight: '80px',
    }
}));

const FooterItem = (props) => {
    const classes = useStyles();
    const { item } = props;

    return (
        <div className={classes.footeritem} onClick={() => (item.Type === FooterType.Clock || item.Type === FooterType.Phone || item.Type === FooterType.Info) ? null : window.open(item.Url)}>
            {(() => {
                switch (item.Type) {
                    case FooterType.Instagram:
                        return <>
                            <FaInstagram color='inherit' size="1.2rem" />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                        </>;
                    case FooterType.Twitter:
                        return <>
                            <FaTwitter color='inherit' size="1.2rem" />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                        </>;
                    case FooterType.Facebook:
                        return <>
                            <FaFacebookSquare color='inherit' size="1.2rem" />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                        </>;
                    case FooterType.Snapchat:
                        return <>
                            <FaSnapchatGhost color='inherit' size="1.2rem" />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                        </>
                    case FooterType.Whatsapp:
                        return <>
                            <FaWhatsapp color='inherit' size="1.2rem" />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                        </>
                    case FooterType.Map:
                        return <>
                            <FaMapMarkerAlt color='inherit' size='1.2rem' />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', maxWidth: '300px' }}>{item.Title}</Typography>
                        </>
                    case FooterType.Clock:
                        return <>
                            <FaClock color='inherit' size='1.2rem' />
                            <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                        </>
                    case FooterType.Phone:
                        return (
                            <>
                                <PhoneEnabledIcon color="inherit" size="small" />
                                <Link href={`tel:${item.Url}`} style={{ paddingLeft: '4px', }}>{item.Title}</Link>
                            </>
                        );
                    case FooterType.Info:
                        return (
                            <>
                                <FaInfoCircle color="inherit" size="1rem" />
                                <Typography color='textSecondary' variant='caption' style={{ paddingLeft: '4px', }}>{item.Title}</Typography>
                            </>
                        );

                    default:
                        return <FaInstagram color='inherit' size="1.2rem" />;
                }
            })()}
        </div>
    )
}

const HorizontalFooterItem = (props) => {
    const classes = useStyles();
    const { item } = props;

    return (
        <div className={classes.footeritem} >
            {(() => {
                switch (item.Type) {
                    case FooterType.Instagram:
                        return <FaInstagram color='inherit' size="1.5rem" onClick={() => window.open(item.Url)} />;
                    case FooterType.Twitter:
                        return <FaTwitter color='inherit' size="1.5rem" onClick={() => window.open(item.Url)} />;
                    case FooterType.Facebook:
                        return <FaFacebookSquare color='inherit' size="1.5rem" onClick={() => window.open(item.Url)} />;
                    case FooterType.Snapchat:
                        return <FaSnapchatGhost color='inherit' size="1.5rem" onClick={() => window.open(item.Url)} />;
                    default:
                        return <FaInstagram color='inherit' size="1.5rem" onClick={() => window.open(item.Url)} />;
                }
            })()}
        </div>
    )
}

const Footer = (props) => {
    const classes = useStyles();
    const { Footer } = props;
    const { Config } = useContext(DataContext);

    if (Footer.Direction === 'v' || Footer.Direction === undefined)
        return (
            <div className={classes.footer}>
                <div className={classes.footeritem}><Typography color='textSecondary' variant='caption'>{Footer.FooterText}</Typography></div>
                {
                    Footer.Items &&
                    Footer.Items.map((item, index) => <FooterItem key={index} item={item} />)
                }
                {Config.GetOrder && <div className={classes.spacer}></div>}
            </div>
        );
    else
        return (
            <div className={classes.footer}>
                <div className={classes.footeritem}><Typography color='textSecondary' variant='caption'>{Footer.FooterText}</Typography></div>
                {
                    Footer.Items &&
                    Footer.Items.filter(item => item.Type > 4).map((item, index) => <FooterItem key={index} item={item} />)
                }
                <div className={classes.horizontalfooteritem}>
                    {
                        Footer.Items &&
                        Footer.Items.filter(item => item.Type <= 4).map((item, index) => <HorizontalFooterItem key={index} item={item} />)
                    }
                    <Typography color='textSecondary' variant='caption' style={{ textAlign: 'center', paddingLeft: '4px', }}>{Footer.SharedUrlText}</Typography>
                </div>
                {Config.GetOrder && <div className={classes.spacer}></div>}
            </div>
        );
}

export default Footer;
