import React, { useState, useEffect } from 'react'
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import BlockIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { get, auth } from '../_api/api';

const useStyles = makeStyles(theme => ({
    orders: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        padding: '24px',
        boxSizing: 'border-box',
    },
    logo: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    actions: {
        display: 'flex',
    },
    ordercomplete: {
        backgroundColor: theme.palette.action.hover,
    },
    wrapper: {
        position: 'relative',
    },
    fabProgress: {
        position: 'absolute',
        top: 4,
        left: 3,
        zIndex: 1,
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '48px',
        marginBottom: '24px',
        padding: '8px',
        boxSizing: 'border-box',
    }
}));

const getStatusText = (status) => {
    if (status === 1)
        return 'Yeni';
    else if (status === 2)
        return 'Yolda';
    else if (status === 3)
        return 'Tamamlandı';
    else if (status === 0)
        return 'İptal Edildi';
}

const OrderDetails = (props) => {
    const { selectedOrder, closeOrderDetails } = props;
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState(null);

    useEffect(() => {
        const fetchOrderItems = async () => {
            setFetching(true); // Set the flag so that SingIn button is disabled

            get(`/Shop/items/${selectedOrder.Id}`)
                .then(response => {
                    setItems(response.data.Data);
                })
                .catch((error) => console.log(error))
                .finally(() => setFetching(false));
        };

        if (selectedOrder) fetchOrderItems();

    }, [selectedOrder]);

    if (!selectedOrder) return '';

    return (
        <Dialog open={(selectedOrder !== null)} onClose={closeOrderDetails} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{`Müşteri: ${selectedOrder.FullName}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`Adres: ${(selectedOrder.Address || '')}`}</DialogContentText>
                <DialogContentText>{`Telefon: ${(selectedOrder.Phone || '')}`}</DialogContentText>
                <DialogContentText>{`Ürün Adedi: ${selectedOrder.Quantity}`}</DialogContentText>
                <DialogContentText>{`Toplam Tutar: ${selectedOrder.Amount} TL`}</DialogContentText>
                <DialogContentText>{`Sipraiş No: ${selectedOrder.Id}`}</DialogContentText>
                {
                    fetching && <LinearProgress />
                }
                {
                    items && selectedOrder && (!fetching) &&
                    <TableContainer>
                        <Table >
                            <TableHead>
                                <TableRow key={0}>
                                    <TableCell>Ürün</TableCell>
                                    <TableCell align="right">Fiyat</TableCell>
                                    <TableCell align="right">Seçenekler</TableCell>
                                    <TableCell align="right">Adet</TableCell>
                                    <TableCell align="right">Tutar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((item) => {
                                    const options = (item.Options && item.Options !== 'null')
                                        ? JSON.parse(item.Options)[0].Items[0].Title
                                        : '';
                                    return (
                                        <TableRow key={item.Id}>
                                            <TableCell component="th" scope="row">{item.Title}</TableCell>
                                            <TableCell align="right">{item.Price}</TableCell>
                                            <TableCell align="right">{options}</TableCell>
                                            <TableCell align="right">{item.Quantity}</TableCell>
                                            <TableCell align="right">{item.Amount}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={closeOrderDetails} color="primary">
                    Kapat
          </Button>
            </DialogActions>
        </Dialog >
    );
}

const OrderOnTheWayButton = (props) => {
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const { orderid, fetchData } = props;

    const onClick = () => {
        setFetching(true); // Set the flag so that SingIn button is disabled

        get(`/Shop/ship-order/${orderid}`)
            .then(() => { setFetching(false); fetchData(); })
            .catch(() => setFetching(false)); // An error occured while fetching shop Config. Return 500

    }

    return (
        <div className={classes.wrapper}>
            <Tooltip title="Sipariş yola çıktı">
                <IconButton color='primary' aria-label="add" size="medium" onClick={onClick}>
                    <MotorcycleIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {
                fetching && <CircularProgress className={classes.fabProgress} />
            }
        </div>
    );
}

const OrderCompleteButton = (props) => {
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const { orderid, fetchData } = props;

    const onClick = () => {
        setFetching(true); // Set the flag so that SingIn button is disabled

        get(`/Shop/deliver-order/${orderid}`)
            .then(() => { setFetching(false); fetchData(); })
            .catch(() => setFetching(false)); // An error occured while fetching shop Config. Return 500

        fetchData();
    }

    return (
        <div className={classes.wrapper}>
            <Tooltip title="Sipariş tamamlandı">
                <IconButton color='primary' aria-label="add" size="medium" onClick={onClick}>
                    <DoneAllIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {
                fetching && <CircularProgress className={classes.fabProgress} />
            }
        </div>
    );
}

const OrderCancelButton = (props) => {
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const { orderid, fetchData } = props;

    const onClick = () => {
        setFetching(true); // Set the flag so that SingIn button is disabled

        get(`/Shop/cancel-order/${orderid}`)
            .then(() => { setFetching(false); fetchData(); })
            .catch(() => setFetching(false)); // An error occured while fetching shop Config. Return 500

        fetchData();
    }

    return (
        <div className={classes.wrapper}>
            <Tooltip title="Sipariş iptal edildi">
                <IconButton color='primary' aria-label="add" size="medium" onClick={onClick}>
                    <BlockIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {
                fetching && <CircularProgress className={classes.fabProgress} />
            }
        </div>
    );
}

const Header = (props) => {
    const classes = useStyles();
    const { orders, fetching, fetchData } = props;

    return (
        <Paper className={classes.header}>
            {
                orders &&
                <Typography>{`Toplam Sipariş: ${orders.length}`}</Typography>
            }
            {
                fetching && <CircularProgress size={30} />
            }
            <Button variant="contained" color="primary" onClick={fetchData}>Tazele</Button>
        </Paper>
    );
}

const Orders = () => {
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orders, setOrders] = useState(false);

    const openOrderDetails = (order) => {
        setSelectedOrder(order);
    }

    const closeOrderDetails = () => {
        setSelectedOrder(null);
    }

    const fetchData = () => {
        setFetching(true); // Set the flag so that SingIn button is disabled

        auth() // Authorize client
            .then(() => {
                get('/Shop/list-orders-by-brand/185')
                    .then(response => {
                        setOrders(response.data.Data);
                    })
                    .finally(() => setFetching(false));
            })
            .catch(() => { setFetching(false); })
    }

    useEffect(() => {
        fetchData(setOrders, setFetching);
    }, []);

    if (!orders) return '';

    return (
        <div className={classes.orders}>
            <Header orders={orders} fetching={fetching} fetchData={fetchData} />
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow key={0}>
                            <TableCell>Sipraiş No</TableCell>
                            <TableCell>Müşteri</TableCell>
                            <TableCell align="right">E-Posta</TableCell>
                            <TableCell align="right">Telefon</TableCell>
                            <TableCell align="right">Ürün adedi</TableCell>
                            <TableCell align="right">Toplam Tutar (TL)</TableCell>
                            <TableCell align="right">Sipariş Zamanı</TableCell>
                            <TableCell align="right">Durum</TableCell>
                            <TableCell >İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => (
                            <TableRow key={order.Id} className={(order.Status === 3) ? classes.ordercomplete : ''}>
                                <TableCell component="th" scope="row">{order.Id}</TableCell>
                                <TableCell component="th" scope="row">{order.FullName}</TableCell>
                                <TableCell align="right">{order.Email}</TableCell>
                                <TableCell align="right">{order.Phone}</TableCell>
                                <TableCell align="right">{order.Quantity}</TableCell>
                                <TableCell align="right">{order.Amount}</TableCell>
                                <TableCell align="right">{moment.utc(order.CreatedAt).add(3, 'h').format("DD MMMM YYYY, hh:mm:ss")}</TableCell>
                                <TableCell align="right">{getStatusText(order.Status)}</TableCell>
                                <TableCell align="right">
                                    <div className={classes.actions} >
                                        <Tooltip title="Sipariş detaylarını gör">
                                            <IconButton color='primary' aria-label="add" size="medium" onClick={() => openOrderDetails(order)}>
                                                <ShoppingCartIcon fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                        <OrderOnTheWayButton orderid={order.Id} fetchData={fetchData} />
                                        <OrderCompleteButton orderid={order.Id} fetchData={fetchData} />
                                        <OrderCancelButton orderid={order.Id} fetchData={fetchData} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <OrderDetails selectedOrder={selectedOrder} closeOrderDetails={closeOrderDetails} />
        </div>

    );
}

export default Orders;