
export function isClientAuthorized() {
    const authData = JSON.parse(localStorage.getItem("authData"));

    if (authData) {
        if ((Math.floor(Date.now() / 1000) - authData.expires_in) < 0) return true
        else {
            localStorage.removeItem("authData");
            return false;
        }
    }
    else return false;
}

export function isMemberAuthorized() {
    const signinData = JSON.parse(localStorage.getItem("signInData"));

    if (signinData) {
        if ((Math.floor(Date.now() / 1000) - signinData.expires_in) < 0) return true
        else {
            localStorage.removeItem("signInData");
            return false;
        }
    }
    else return false;
}

export function setAuthData(response) {
    localStorage.setItem("authData", JSON.stringify(response.data));
}

export function setSignInData(response) {
    localStorage.setItem("signInData", JSON.stringify(response.data));
}

export function getToken() {
    let signInData = JSON.parse(localStorage.getItem("signInData"));

    if ((signInData === undefined) || (signInData === null)) {
        console.log("!!! Not SignedIn. Refreshing token...");
    }
    else {
        if ((Math.floor(Date.now() / 1000) - signInData.expires_in) < 0) {
            return signInData.token_type + " " + signInData.access_token;
        }
        else {
            console.log("!!! SignedIn expired. Refreshing token...");
            localStorage.removeItem("authData");
        }
    }
}

export function getRefreshToken() {
    let signInData = JSON.parse(localStorage.getItem("signInData"));
    return signInData.refresh_token;
}

export function getAuthToken() {
    let authData = JSON.parse(localStorage.getItem("authData"));

    if ((authData === undefined) || (authData === null)) {
        console.log("!!! Not Authenticated. Refreshing token...");
    }
    else {
        if ((Math.floor(Date.now() / 1000) - authData.expires_in) < 0) {
            return authData.token_type + " " + authData.access_token;
        }
        else {
            console.log("!!! Authentication expired. Refreshing token...");
            localStorage.removeItem("authData");
        }
    }
}