import React, { useContext } from 'react'

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Components
import Price from '../../price.component';

// Contexts
import { DataContext } from '../../../_contexts/data.context';
import { CartContext } from '../../../_contexts/cart.context';

const useStyles = makeStyles(theme => ({
    itemwithcart: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: '440px',
        minHeight: '68px',
        padding: '8px 8px 8px 0px',
        boxSizing: 'border-box',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '4px 8px 4px 4px',
    },
    title: {
        width: '100%',
    },
    subtitle: {
        width: '100%',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    spacer: {
        width: '16px',
        height: '100%'
    },
    progress: {
        paddingLeft: '12px',
        paddingRight: '12px',
        height: '24px',
    },
}));

const ActionButtons = (props) => {
    const classes = useStyles();
    const { Item } = props;
    const { Config, Credentials } = useContext(DataContext); // Get Config of this menu from the Data Context
    const { getItem, addItemToCart, incrementQuantity, decrementQuantity, cartStatus } = useContext(CartContext); // Functions that we will use in Cart operations

    if (Config.GetOrder) {
        if (Item.NoDetails) {
            const cartItem = getItem(Item.ProductId);

            if (cartItem) {
                return (
                    <>
                        {
                            (cartStatus.action === 'increment item') && (cartStatus.id === cartItem.Id)
                                ?
                                <div className={classes.progress}><CircularProgress size={24} color='primary' /></div>
                                :
                                <IconButton color='primary' aria-label="add" size="medium" onClick={() => incrementQuantity(cartItem.Id)}>
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                        }
                        <Typography color='textPrimary' variant='body1' >{cartItem.Quantity}</Typography>
                        {
                            (cartStatus.action === 'decrement item') && (cartStatus.id === cartItem.Id)
                                ?
                                <div className={classes.progress}><CircularProgress size={24} color='primary' /></div>
                                :
                                <IconButton color='primary' aria-label="remove" size="medium" onClick={() => decrementQuantity(cartItem.Id)}>
                                    <RemoveIcon fontSize="inherit" />
                                </IconButton>
                        }
                    </>
                );
            }
            else {
                const itemToAdd = {
                    'Title': Item.Title,
                    'ProductId': Item.ProductId,
                    'Quantity': 1,
                    'CurrencyId': Credentials.SelectedCurrencyId,
                    'Price': Item.Price
                };

                return (
                    <>
                        {
                            (cartStatus.action === 'adding item') && (cartStatus.id === Item.ProductId)
                                ?
                                <div className={classes.progress}><CircularProgress size={24} color='primary' /></div>
                                :
                                <IconButton color='primary' aria-label="add" size="medium" onClick={() => addItemToCart(itemToAdd)}>
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                        }
                    </>
                );
            }
        }
        else {
            // This item has options and extras. We will show "Add" button, but when clicked it will open product details page
            // Additionaly, we must get the list of the prices from the Options with Type=2 of the item
            return (
                <IconButton color='primary' aria-label="add" size="medium" >
                    <AddIcon fontSize="inherit" />
                </IconButton>
            );
        }
    }
    else {
        // Cart and Order operations are not allowed. So we will just display item data
        if (Item.NoDetails) {
            // This item does not have extras and options. We just display the item with price
            return <div className={classes.spacer} />;
        }
        else {
            // This item has extras and options. We will add arrow icon to open product details
            return (
                <IconButton color='primary' aria-label="add" size="medium" >
                    <ArrowRightIcon fontSize="inherit" />
                </IconButton>
            );
        }
    }
}

const MenuItemWithCart = (props) => {
    const classes = useStyles();
    const { Item, openProductDetails } = props; // Get the Menu Item and the function to open product details from the props

    return (
        <div className={classes.itemwithcart} onClick={() => { if (!Item.NoDetails) openProductDetails(Item) }}>
            <ActionButtons Item={Item} />
            <div className={classes.content}>
                <Typography className={classes.title} variant='body2' color='textPrimary'>{Item.Title}</Typography>
                {
                    Item.SubTitle &&
                    <Typography className={classes.subtitle} color='textSecondary' variant='caption'>{Item.SubTitle}</Typography>
                }
            </div>
            <Price Item={Item} />
        </div>
    );
}

export default MenuItemWithCart;