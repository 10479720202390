import React from 'react'
import clsx from 'clsx';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Helpers
import { getLocalizedMessage } from '../_helpers/localization';

const useStyles = makeStyles(() => ({
    error: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    padded: {
        padding: '8px',
    },
    logo: {
        width: "160px",
    }
}));

const Error = (props) => {
    const classes = useStyles();
    const { ReturnCode, Language } = props;

    const Message = getLocalizedMessage(ReturnCode === 77 ? 'brandchurned' : 'systemerror', Language);

    const Caption = Message.Caption;
    const Title = `${Message.Title} - ${ReturnCode}`;

    return (
        <div className={classes.error}>
            <img className={clsx([classes.padded, classes.logo])} src='/assets/img/error.png' alt="An unexpected error occured. Please try again" />
            <Typography className={classes.padded} variant='body1'>{Caption}</Typography>
            <Typography className={classes.padded} variant='body1'>{Title}</Typography>
        </div>
    );
}

export default Error;