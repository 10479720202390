import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { DataContext } from '../../_contexts/data.context';

const useStyles = makeStyles(() => ({
    raiseditem: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        height: '72',
        minHeight: '72px',
    },
    merchant: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '4px',
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    subtitle: {
        width: '100%',
    },
}));

const Merchant = (props) => {
    const classes = useStyles();
    const { Merchant } = props;
    const { selectMerchant } = useContext(DataContext);

    return (
        <div className={classes.raiseditem}>
            <Paper className={classes.paper} elevation={3}>
                <div key={Merchant.Id} className={classes.merchant} onClick={() => selectMerchant(Merchant)}>
                    <div className={classes.content}>
                        <div className={classes.title}><Typography variant='body1' color='primary'>{Merchant.Name}</Typography></div>
                        <div className={classes.subtitle}><Typography variant='caption' color='textPrimary'>{Merchant.Address}</Typography></div>
                    </div>
                    <div><Typography variant='body2' color='primary' noWrap>{(Merchant.EstimatedServiceMinute == null) ? "" : '~' + Merchant.EstimatedServiceMinute + ' dk'}</Typography></div>
                    <ArrowRightIcon color='primary' />
                </div>
            </Paper>
        </div>
    );
}

export default Merchant;