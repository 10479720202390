import React, { createContext, useState, useContext, useEffect } from 'react';

// Contexts
import { DataContext } from './data.context';

// Api
import { post, get } from '../_api/api';

// Helpers
import { getCustomer, saveCustomer } from '../_helpers/helperfunctions';

const CartContext = createContext();

function CartProvider(props) {
    const [cart, setCart] = useState(null);
    const [cartStatus, setCartStatus] = useState({});
    const { Credentials } = useContext(DataContext);
    const [Customer,] = useState(getCustomer());

    const addItemToCart = (Item) => {
        setCartStatus({ action: 'adding item', id: Item.ProductId });

        if (!cart) {
            const Cart = {
                'Type': 1,
                'MenuId': Credentials.MenuId,
                'BrandId': Credentials.BrandId,
                'PassId': Credentials.PassId,
                'SubscriberId': Credentials.SubscriberId,
                'MerchantId': Credentials.MerchantId,
                'BWId': Credentials.BWId,
                'CurrencyId': Credentials.SelectedCurrencyId
            };

            post('/Shop/create-cart', Cart)
                .then(response => {
                    post('/Shop/add-cart-item', { ...Item, CartId: response.data.Data.Id })
                        .then(response => {
                            setCart(response.data.Data);
                            setCartStatus({ action: 'item added', id: Item.ProductId });
                        })
                        .catch(() => { setCartStatus({ action: 'fetch error' }); });
                })
                .catch(() => { setCartStatus({ action: 'fetch error' }); });
        }
        else {
            post('/Shop/add-cart-item', { ...Item, CartId: cart.Id })
                .then(response => {
                    setCart(response.data.Data);
                    setCartStatus({ action: 'item added', id: Item.ProductId });
                })
                .catch(() => { setCartStatus({ action: 'fetch error' }); });
        }
    };

    const checkoutCart = (values) => {
        setCartStatus({ action: 'checkingout' });
        saveCustomer(values);

        if (cart) {
            post('/Shop/checkout', { CartId: cart.Id, ...values })
                .then(() => {
                    setCartStatus({ action: 'checkout successful' });
                })
                .catch(() => { setCartStatus({ action: 'checkout error' }); });
        }
    };

    const incrementQuantity = (Id) => {
        setCartStatus({ action: 'increment item', id: Id });

        get('/Shop/increase-cart-item/' + Id)
            .then(response => {
                setCart(response.data.Data);
                setCartStatus({ action: 'item incremented', id: Id });
            })
            .catch(() => { setCartStatus({ action: 'increment error' }); });
    }

    const decrementQuantity = (Id) => {
        setCartStatus({ action: 'decrement item', id: Id });

        get('/Shop/decrease-cart-item/' + Id)
            .then(response => {
                setCart(response.data.Data);
                setCartStatus({ action: 'item decremented', id: Id });
            })
            .catch(() => { setCartStatus({ action: 'decrement error' }); });
    }

    const removeItemFromCart = (Id) => {
        setCartStatus({ action: 'removing item', id: Id });

        get('/Shop/remove-cart-item/' + Id)
            .then(response => {
                setCart(response.data.Data);
                setCartStatus({ action: 'item removed', id: Id });
            })
            .catch(() => { setCartStatus({ action: 'remove error' }); });
    }

    const fetchCart = () => {
        const params = {
            'MenuId': Credentials.MenuId,
            'BWId': Credentials.BWId,
        }

        setCartStatus({ action: 'fetching' });

        post('/Shop/get-cart', params)
            .then(response => {
                setCart(response.data.Data);
                setCartStatus({ action: 'fetched cart' });
            })
            .catch(() => {
                setCartStatus({ action: 'fetch error' });
            });
    }

    const getItem = (ProductId) => cart && cart.Items.find(item => item.ProductId === ProductId);

    useEffect(() => {
        const fetchData = async () => {
            fetchCart();
        };

        if (Credentials.MenuId && Credentials.BWId) fetchData(); // If we have a menuId and a BWId, fetch the cart
    }, [Credentials.MenuId, Credentials.BWId]);

    return (
        <CartContext.Provider
            value={{
                Cart: cart,
                Customer,
                ItemCount: cart ? cart.Items.length : 0,
                TotalQuantity: cart ? cart.Quantity : 0,
                TotalPrice: cart ? cart.Amount : 0,
                addItemToCart,
                checkoutCart,
                decrementQuantity,
                fetchCart,
                getItem,
                incrementQuantity,
                removeItemFromCart,
                cartStatus,
                fetchingCart: cartStatus.action === 'fetching',
                checkingOut: cartStatus.action === 'checkingout'
            }}>
            {props.children}
        </CartContext.Provider>
    );
}

export { CartContext, CartProvider };