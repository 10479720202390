import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { DataProvider } from './_contexts/data.context';
import { CartProvider } from './_contexts/cart.context';
import './index.css';

ReactDOM.render(
    <DataProvider >
        <CartProvider>
            <App />
        </CartProvider>
    </DataProvider>,
    document.getElementById('root')
);