import React, { useState, forwardRef } from 'react'
import { ItemType } from '../globals';
import { isMobileOnly } from 'react-device-detect';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

// Components
import CartFab from '../_components/cart/cartfab.component'
import Carousel from '../_components/menu/carousel.component'
import MenuItem from '../_components/menu/item.component'
import Product from '../_pages/product.page';

// Transition
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    subcategories: {
        width: '100%',
        height: '100%',
        maxWidth: '440px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
    },
    image: {
        width: '100%',
        height: '150px',
        minHeight: '150px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    item: {
        width: '100%',
        maxWidth: '440px',
        padding: '8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    backbutton: {
        position: 'absolute',
        left: '12px',
        top: '12px',
        backgroundColor: '#fafafa',
        opacity: '0.75',
        zIndex: '1500',
    },
    spacer: {
        width: '100%',
        maxWidth: '440px',
        height: '70px',
        minHeight: '70px',
    },
}));

const SubCategories = (props) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedItem, setSelectedItem] = useState({});
    const [pageOpen, setPageOpen] = useState(false);
    const { Item, closePage } = props;

    let ItemList = [];

    // First try to get the subcategory Items
    const subcategoryitems = Item.Items.filter(item => item.Type === ItemType.SubCategory);

    // If we do not habe any subcategories, we will not display the carousel menu, just list the products
    if (subcategoryitems && subcategoryitems.length === 0)
        ItemList = Item.Items;
    else
        ItemList = subcategoryitems[selectedTab].Items

    const openProductDetails = (item) => { setSelectedItem(item); setPageOpen(true); }
    const closeProductDetails = () => setPageOpen(false);

    return (
        <div className={classes.subcategories}>
            <IconButton aria-label="back" color='primary' className={classes.backbutton} onClick={closePage}>
                <ExpandMoreIcon />
            </IconButton>
            {
                Item.ImageUrl &&
                <div className={classes.image} style={{ backgroundImage: `url(${Item.ImageUrl})` }}></div>
            }
            <div className={classes.item}><Typography variant='h5' color='textPrimary'>{Item.Title}</Typography></div>
            {
                subcategoryitems.length > 0 &&
                <Carousel Items={subcategoryitems} selected={selectedTab} setSelected={setSelectedTab} />
            }
            {
                ItemList.map(i => <MenuItem key={i.Id} Item={i} openProductDetails={openProductDetails} />)
            }
            <div className={classes.spacer} />
            <CartFab />
            <Dialog fullScreen={isMobileOnly} open={pageOpen} onClose={closeProductDetails} TransitionComponent={Transition}>
                <Product Product={selectedItem} closeProductDetails={closeProductDetails} />
            </Dialog>
        </div>
    );
}

export default SubCategories;