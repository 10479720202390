export default function handleError(error) {
    const { status, message } = error;

    switch (status) {
        case 400:
            console.log('bad request ->', error);
            break;
        case 401:
            console.log('unauthenticated ->', error);
            break;
        case 403:
            console.log('unauthorized ->', error);
            break;
        case 500:
            console.log('service exploded ->', error);
            break;
        default:
            console.log(error);
    }
    return message; // I like to get my error message back
}