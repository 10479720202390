import React, { useContext, useState } from 'react'
import { isMobileOnly } from 'react-device-detect';

// Material Ui
import { makeStyles } from '@material-ui/core/styles';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';

// Pages
import Cart from '../../_pages/cart.page';

// Contexts
import { CartContext } from '../../_contexts/cart.context';

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
    fabroot: {
        position: 'fixed',
        right: '16px',
        bottom: '24px',
    },
    extendedIcon: {
        marginRight: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const CartFab = () => {
    const classes = useStyles();
    const { ItemCount, TotalQuantity } = useContext(CartContext);
    const [cartOpen, setCartOpen] = useState(false);

    const openCart = () => setCartOpen(true);
    const closeCart = () => setCartOpen(false);

    return (
        <>
            <Fab className={(ItemCount > 0) ? classes.fabroot : classes.hidden} onClick={openCart} color="primary" aria-label="shop" variant="extended" >
                <Badge badgeContent={TotalQuantity} color='error' className={classes.extendedIcon} >
                    <ShoppingBasketIcon />
                </Badge>
                        SEPETE GİT
                    </Fab>
            <Dialog fullScreen={isMobileOnly} open={cartOpen} onClose={closeCart} TransitionComponent={Transition}>
                <Cart closeCart={closeCart} />
            </Dialog>
        </>
    );
}

export default CartFab;