import { useState, useCallback, useEffect } from 'react'
import { useFormInput } from './useFormInput';

export function useForm(defaultValues) {
    const [values, setValues] = useState(defaultValues);
    const [, setMounted] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [formSubmitting, setSubmitting] = useState(false);

    const handleError = useCallback((name, isValid) => {
        let errors = formErrors;
        const index = errors.findIndex(error => error === name);

        if (!isValid) {
            if (index < 0) errors.push(name);
        } else {
            if (index > -1) errors.splice(index, 1);
        }

        setFormErrors(errors);
    }, [formErrors]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const useInput = (name, isrequired, type, helpertext) => useFormInput({
        name,
        isrequired,
        type,
        helpertext,
        values,
        setValues,
        handleError,
        formSubmitting,
        setSubmitting
    });

    return {
        values,
        setValues,
        setSubmitting,
        useInput,
        errors: formErrors,
        canSubmit: (formErrors.length === 0)
    };
}