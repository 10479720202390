import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    item: {
        width: '100%',
        padding: '8px',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    title: {
        textAlign: 'center',
    },
}));

function SubCategory(props) {
    const classes = useStyles();

    const { item } = props;

    return (
        <div className={classes.item}>
            <Typography color='textSecondary' className={classes.title} variant='h6'>{item.Title}</Typography>
        </div>
    );
}

export default SubCategory