import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    spinner: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    logo: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
    progress: {
        width: '20%',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    }
}));

const Spinner = () => {
    const classes = useStyles();

    return (
        <div className={classes.spinner}>
            <CircularProgress color='primary' size={50} />
        </div>
    );
}

export default Spinner;

/**
 *  <img className={classes.logo} alt="BrandWallet Logo" src="assets/img/brandwallet.jpg" />
            <br />
 */