import axios from 'axios';
import handleError from './handleerror';
import { getAuthToken, isClientAuthorized, setAuthData } from '../_helpers/authhelper';

// { ClientKey: 'WwCpkwrK8YyDuCLwNzcPZsuHhxA7pgmA', ClientSecret: '2w272wk3wpgRgPcF' }
// { ClientKey: 'adminkey', ClientSecret: 'adminsecret' }

//const SERVER_DOMAIN = "http://localhost:5000/api/v1";
//const SERVER_DOMAIN = "https://bw-api-staging.azurewebsites.net/api/v1";
const SERVER_DOMAIN = "https://api2.brand-wallet.com/api/v1";

const getHeaders = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getAuthToken(),
        },
    };
};

const getAuthHeaders = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };
};

// HTTP POST Request for auth api call - Returns Resolved or Rejected Promise
export const auth = () => {
    return new Promise((resolve, reject) => {
        if (!isClientAuthorized()) {
            axios.post(`${SERVER_DOMAIN}${'/OAuth/authorize'}`, { ClientKey: 'WwCpkwrK8YyDuCLwNzcPZsuHhxA7pgmA', ClientSecret: '2w272wk3wpgRgPcF' }, getAuthHeaders())
                .then(response => {
                    setAuthData(response);
                    resolve(response);
                })
                .catch(error => { reject(handleError(error)) });
        }
        else {
            resolve(true);
        }
    });
};

// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path) => {
    return new Promise((resolve, reject) => {
        axios.get(`${SERVER_DOMAIN}${path}`, getHeaders())
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

// HTTP POST Request - Returns Resolved or Rejected Promise
export const post = (path, data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_DOMAIN}${path}`, data, getHeaders())
            .then(response => { resolve(response) })
            .catch((error) => { reject(error.response.data); });
    });
};

// HTTP PATCH Request - Returns Resolved or Rejected Promise
export const patch = (path, data) => {
    return new Promise((resolve, reject) => {
        axios.patch(`${SERVER_DOMAIN}${path}`, data, getHeaders())
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

// HTTP DELETE Request - Returns Resolved or Rejected Promise
export const del = (path) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${SERVER_DOMAIN}${path}`, getHeaders())
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};